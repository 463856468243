import styled from '@emotion/native';
import {space, system, typography} from 'styled-system';
import {Pressable} from 'react-native';
import React from 'react';
import {Text} from './Text';

const LinkLogic = ({children, textStyle, ...rest}) => {
  return (
    <Pressable {...rest}>
      <Text variant={'link'} {...textStyle}>
        {children}
      </Text>
    </Pressable>
  );
};

export const Link = styled(LinkLogic)(
  space,
  typography,
  system({
    cursor: {
      property: 'cursor',
    },
  }),
);

Link.defaultProps = {
  fontSize: 2,
  cursor: 'pointer',
};
