import {Dimensions} from 'react-native';

export const tabletOrSmallerMobile = () => {
  const {width} = Dimensions.get('window');
  return width < 767;
};

export const toastIsLarger = () => {
  const {width} = Dimensions.get('window');
  return width > 400;
};

export const isWeb = () => {
  return typeof document !== 'undefined';
};

// Native
export const isEmpty = (obj) =>
  [Object, Array].includes((obj || {}).constructor) &&
  !Object.entries(obj || {}).length;
