import React from 'react';
import {WebNavBar} from '../web-nav-bar/WebNavBar';
import {Divider} from 'react-native-elements';

//This will need to be different for mobile and web
//Web will be a navbar and mobile will be added if needed to go back
export const Header = () => {
  return (
    <>
      <WebNavBar />
      <Divider />
    </>
  );
};
