export const colors = {
  gray: '#CCC',
  text: '#333',
  blue1: '#2684FF',
  dividerGrey: '#CCC',
  error: '#FF9494',

  toastText: '#0A0A0A',
  toastBackground: '#FFF',
  toastBorder: '#E2E8F0',
  toastMuted: '#F0F1F3',
  toastSuccess: '#7DBE31',
  toastError: '#FC0021',
  toastInfo: '#00FFFF',
};

const breakpoints = ['300px', '480px', '768px', '1024px', '1400px'];

//aliases
breakpoints.sm = breakpoints[0];
breakpoints.md = breakpoints[1];
breakpoints.lg = breakpoints[2];
breakpoints.xl = breakpoints[3];
breakpoints.xxl = breakpoints[4];

export const theme = {
  colors,
  breakpoints,

  fontSizes: [12, 14, 16, 20, 24, 32, 48, 64, 72],

  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],

  radii: [0, 2, 4, 10, 20],

  fontWeights: [400, 700],
};
