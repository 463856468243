import styled from '@emotion/native';
import {
  border,
  borders,
  boxShadow,
  color,
  flexbox,
  layout,
  space,
  typography,
  variant,
} from 'styled-system';
import css from '@styled-system/css';
import {TextInput as NativeTextInput, View} from 'react-native';
import {isWeb} from '../utils/utils';
import React, {useState} from 'react';

export const TextInput = ({
  children,
  webHeight,
  height,
  onBlur: propsOnBlur,
  onChange: propsOnChange,
  ...props
}) => {
  const [focused, setFocused] = useState(false);
  height = webHeight && isWeb() ? webHeight : height;

  const onChange = (data) => {
    //This is done otherwise a synthetic event is sent back
    propsOnChange(data.nativeEvent.text);
  };

  const onBlur = () => {
    if (propsOnBlur) {
      propsOnBlur();
    }
    setFocused(false);
  };

  return (
    <StyledTextInputView focused={focused} height={height}>
      <StyledTextInput
        focused={focused}
        onFocus={() => setFocused(true)}
        onBlur={onBlur}
        onChange={onChange}
        {...props}
      />
    </StyledTextInputView>
  );
};

const StyledTextInputView = styled(View)(
  color,
  space,
  layout,
  flexbox,
  borders,
  (props) =>
    props.focused &&
    variant({
      prop: 'stateVariant',
      variants: {
        primary: {
          borderColor: 'blue1',
        },
      },
    }),
);

StyledTextInputView.defaultProps = {
  stateVariant: 'primary',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'row',
  borderWidth: 2,
  borderStyle: 'solid',
  borderRadius: 3,
  width: 256,
};

const StyledTextInput = styled(NativeTextInput)(
  color,
  space,
  typography,
  border,
  layout,
  boxShadow,
  css({outline: 'none'}),
  (props) =>
    props.focused &&
    variant({
      prop: 'stateVariant',
      variants: {primary: {}},
    }),
);

TextInput.defaultProps = {
  px: 2,
  py: 1,
  stateVariant: 'primary',
  fontSize: 2,
  width: '100%',
  // fontFamily: 'Roboto',
};
