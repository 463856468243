import React from 'react';
import {Routes} from './Routes';
import {ThemeProvider} from 'emotion-theming';
import {ThemeProvider as StyledComponentsThemeProvider} from 'styled-components';
import {theme} from './theme/theme';
import {Header} from './components/header/Header';
import {Router} from './router/router';
import {Amplify} from 'aws-amplify';
import {config} from './config';
import iconFont from 'react-native-vector-icons/Fonts/FontAwesome5_Regular.ttf';
import iconFontSolid from 'react-native-vector-icons/Fonts/FontAwesome5_Solid.ttf';
import iconFontFeather from 'react-native-vector-icons/Fonts/Feather.ttf';
import {MobileNavBar} from './components/mobile-nav-bar/MobileNavBar';
import 'react-native-match-media-polyfill';
import {Container} from './components/Container';
import UserAuthProvider from './context/UserAuthContext';
import toastTheme from './theme/toastTheme';
import {ToastProvider} from 'react-native-styled-toast';
import Icon from 'react-native-vector-icons/Feather';
import {SafeAreaContainer} from './components/SafeViewContainer';

Icon.loadFont();

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID,
  },
  API: {
    endpoints: [
      {
        name: 'api',
        endpoint: config.api.URL,
        region: config.api.REGION,
      },
    ],
  },
});

Icon.loadFont();

if (typeof document !== 'undefined') {
  // I'm on the web!
  // Generate required css
  const iconFontStyles = `@font-face {
  src: url(${iconFont});
  font-family: FontAwesome5_Regular;
}@font-face {
  src: url(${iconFontSolid});
  font-family: FontAwesome5_Solid;
}@font-face {
  src: url(${iconFontFeather});
  font-family: Feather;
}`;

  // Create stylesheet
  const style = document.createElement('style');
  style.type = 'text/css';
  if (style.styleSheet) {
    style.styleSheet.cssText = iconFontStyles;
  } else {
    style.appendChild(document.createTextNode(iconFontStyles));
  }

  // Inject stylesheet
  document.head.appendChild(style);
}

const App = () => {
  return (
    <Router>
      <ThemeProvider theme={theme}>
        <StyledComponentsThemeProvider theme={toastTheme}>
          <ToastProvider maxToasts={2}>
            <UserAuthProvider>
              <SafeAreaContainer
                flex={1}
                webHeight={'100vh'}
                flexDirection={'column'}
                alignItems={'stretch'}>
                <Header />
                <Container
                  justifyContent={'flex-start'}
                  flexDirection={'column'}
                  flex={1}
                  overflow={'visible'}
                  alignItems={'stretch'}>
                  <Routes />
                </Container>
                <MobileNavBar />
              </SafeAreaContainer>
            </UserAuthProvider>
          </ToastProvider>
        </StyledComponentsThemeProvider>
      </ThemeProvider>
    </Router>
  );
};

export default App;
