import React from 'react';
import FeatherIcon from 'react-native-vector-icons/dist/Feather';
import {Container} from './components/Container';
import {colors} from './theme/theme';

export const Icon = ({
  iconName,
  size,
  color,
  activeColor,
  focusedColor,
  pressedColor,
  hoveredColor,
  active,
  focused,
  pressed,
  hovered,
  ...props
}) => {
  if (activeColor && active) {
    color = activeColor;
  } else if (focusedColor && focused) {
    color = focusedColor;
  } else if (pressedColor && pressed) {
    color = pressedColor;
  } else if (hoveredColor && hovered) {
    color = hoveredColor;
  }

  if (colors.hasOwnProperty(color)) {
    color = colors[color];
  }

  return (
    <Container {...props}>
      <FeatherIcon name={iconName} size={size} color={color} />
    </Container>
  );
};
