const dev = {
  cognito: {
    REGION: 'us-east-1',
    USER_POOL_ID: 'us-east-1_PLaouJfvf',
    APP_CLIENT_ID: '6f1ah8ufl3krndhs1d7gkb916l',
    IDENTITY_POOL_ID: 'us-east-1:eb39d085-dcfc-4adb-bf4f-34ff6a214fc8',
  },
  api: {
    REGION: 'us-east-1',
    URL: 'https://ebubewq3c1.execute-api.us-east-1.amazonaws.com/dev',
  },
};

let configs = [];
configs.dev = dev;
// configs["test"] = test;
// configs["demo"] = demo;
// configs["prod"] = prod;

// Default to dev if not set
let stage = process.env.REACT_APP_STAGE;
if (!stage) {
  stage = 'dev';
}
export const config = configs[stage];
