import styled from '@emotion/native';
import {
  color,
  flexbox,
  layout,
  space,
  system,
  typography,
  variant,
} from 'styled-system';
import {Pressable as NativePressable, View} from 'react-native';
import React from 'react';

export const Pressable = ({
  children,
  onPress,
  pressableStyles,
  active,
  ...props
}) => {
  return (
    <StyledPressable onPress={onPress} {...pressableStyles}>
      {({pressed, hovered, focused}) => (
        <StyledPressableView
          pressed={pressed}
          hovered={hovered}
          focused={focused}
          active={active}
          {...props}>
          {React.Children.map(children, (c) => {
            if (c) {
              return React.cloneElement(c, {
                pressed: pressed,
                hovered: hovered,
                focused: focused,
                active: active,
              });
            }
          })}
        </StyledPressableView>
      )}
    </StyledPressable>
  );
};

const PressableView = ({children, ...props}) => {
  // console.log(props); //This is used to test the state and hovered isn't working
  return <View {...props}>{children}</View>;
};

const StyledPressableView = styled(PressableView)(
  space,
  typography,
  color,
  layout,
  flexbox,
  system({
    cursor: {
      property: 'cursor',
    },
  }),
  (props) =>
    props.focused &&
    variant({
      prop: 'stateVariant',
      variants: {primary: {}},
    }),
  //focus flashes before pressed.
  (props) =>
    props.pressed &&
    variant({
      prop: 'stateVariant',
      variants: {primary: {}},
    }),
  //Hover not working even on react-native-web@canary
  (props) =>
    props.hovered &&
    variant({
      prop: 'stateVariant',
      variants: {primary: {bg: 'red'}},
    }),
);

StyledPressableView.defaultProps = {
  fontSize: 2,
  cursor: 'pointer',
  stateVariant: 'primary',
  alignSelf: 'center',
  width: '100%',
};

const StyledPressable = styled(NativePressable)(
  space,
  typography,
  color,
  layout,
  system({
    cursor: {
      property: 'cursor',
    },
  }),
  variant({
    prop: 'spacing',
    variants: {equal: {flex: 1}},
  }),
);
