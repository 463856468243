import React, {createContext, useState} from 'react';
import {Auth} from '@aws-amplify/auth';
import {getProfile} from '../api/userApi';
import {isEmpty} from '../utils/utils';

export const UserAuthContext = createContext();

const UserAuthProvider = ({children}) => {
  const [currentUser, setCurrentUser] = useState({});
  const [auth, setAuth] = useState(null);

  const checkAuth = async () => {
    let authUser = await Auth.currentAuthenticatedUser();
    if (isEmpty(currentUser) && authUser) {
      let user = await getProfile();
      setCurrentUser(user);
    }
    setAuth(authUser);
    return authUser;
  };

  const signIn = async (email, password) => {
    let authUser = await Auth.signIn(
      String(email).toLowerCase(),
      String(password),
    );
    let user = await getProfile();
    setCurrentUser(user);
    setAuth(authUser);
    return authUser;
  };

  const signOut = async () => {
    await Auth.signOut();
    setAuth(null);
  };

  return (
    <UserAuthContext.Provider
      value={{
        currentUser,
        auth,
        checkAuth,
        signIn,
        signOut,
      }}>
      {children}
    </UserAuthContext.Provider>
  );
};

export default UserAuthProvider;
