import {Container} from '../components/Container';
import {useForm} from 'react-hook-form';
import React, {useEffect} from 'react';
import {Button} from '../components/Button';
import {TextInput} from '../components/TextInput';
import {ReactHookFormComponent} from '../components/react-hook-form-components/ReactHookFormComponent';
import {Auth} from '@aws-amplify/auth';
import {useHistory} from 'react-router-dom';
import queryString from 'query-string';
import * as yup from 'yup';
import {yupResolver} from '@hookform/resolvers';
import {Link} from '../components/Link';
import {useToast} from 'react-native-styled-toast';
import {errorToastConfig} from '../theme/toastConfigs';

const SignupConfirmationSchema = yup.object().shape({
  email: yup.string().required(),
  confirmationCode: yup.string().required(),
});

export const SignupConfirmation = () => {
  const history = useHistory();

  const queryParams = queryString.parse(history.location.search);
  const locationEmail = queryParams.email;
  const confirmationCode = queryParams.code;
  const {toast} = useToast();

  const ConfirmSignUp = async (email, code) => {
    try {
      await Auth.confirmSignUp(email.toLowerCase(), code);
      //TODO add message on the login screen to say that we are redirect to login for security reasons
      history.push(`/login?email=${email}`);
    } catch (e) {
      toast({
        message: 'Something Went Wrong',
        subMessage: e.message,
        duration: 0,
        ...errorToastConfig(),
      });
    }
  };

  useEffect(() => {
    //This should be fixed
    const ConfirmSignUpUseEffect = async (email, code) => {
      try {
        await Auth.confirmSignUp(email.toLowerCase(), code);
        //TODO add message on the login screen to say that we are redirect to login for security reasons
        history.push(
          `/login?email=${email}&message=Your account has been created, for security reasons we need you to signin again`,
        );
      } catch (e) {
        toast({
          message: 'Something Went Wrong',
          subMessage: e.message,
          duration: 0,
          ...errorToastConfig(),
        });
      }
    };

    if (confirmationCode && locationEmail) {
      ConfirmSignUpUseEffect(locationEmail, confirmationCode);
    }
  }, [confirmationCode, history, locationEmail, toast]);

  const {errors, control, handleSubmit} = useForm({
    mode: 'onBlur',
    defaultValues: {
      email: locationEmail,
      confirmationCode: confirmationCode,
    },
    resolver: yupResolver(SignupConfirmationSchema),
  });

  const Submit = async (data) => {
    await ConfirmSignUp(data.email, data.confirmationCode);
    history.push(
      `/login?email=${data.email.toLowerCase()}&message=Your account has been created, for security reasons we need you to signin again`,
    );
  };

  return (
    <Container flexDirection={'column'}>
      <ReactHookFormComponent
        errors={errors}
        control={control}
        name={'email'}
        component={<TextInput />}
        label={'Email'}
      />
      <ReactHookFormComponent
        errors={errors}
        control={control}
        name={'confirmationCode'}
        component={<TextInput />}
        label={'Confirmation Code'}
      />
      <Button onPress={handleSubmit(Submit)} title={'Confirm'} />
      <Link
        onPress={() =>
          history.push(`/signup/confirm/resend?email=${locationEmail}`)
        }>
        Didn't work? resend confirmation email
      </Link>
    </Container>
  );
};
