import {Pressable} from '../StyledPressable';
import {Icon} from '../../Icon';
import {Text} from '../Text';
import React from 'react';

export const NavBarButton = ({
  label,
  iconName,
  onPress,
  width = 68,
  pressableStyles,
  active,
}) => {
  return (
    <Pressable
      onPress={onPress}
      width={width}
      pt={2}
      pb={1}
      pressableStyles={pressableStyles}
      active={active}>
      <Icon
        iconName={iconName}
        size={30}
        color={'black'}
        focusedColor={'blue1'}
        activeColor={'blue1'}
        pressedColor={'blue1'}
      />
      {label && (
        <Text stateVariant={'navBarButton'} fontSize={0} textAlign={'center'}>
          {label}
        </Text>
      )}
    </Pressable>
  );
};
