import {Container} from '../Container';
import {NavBarButtonTabs} from '../web-nav-bar/NavBarButtonTabs';
import React, {useContext} from 'react';
import {useMediaQuery} from 'react-responsive';
import {isWeb, tabletOrSmallerMobile} from '../../utils/utils';
import {UserAuthContext} from '../../context/UserAuthContext';

export const MobileNavBar = () => {
  const userAuthContext = useContext(UserAuthContext);

  let tabletOrSmaller;
  //Can't get this to work on mobile:(
  //TODO Think I would prefer a hamburger on web.
  tabletOrSmaller = useMediaQuery({
    maxWidth: 767,
  });
  if (!isWeb()) {
    tabletOrSmaller = tabletOrSmallerMobile();
  }

  return (
    <>
      {tabletOrSmaller && userAuthContext.auth && (
        <Container
          bg={'white'}
          borderTopColor={'dividerGrey'}
          borderTopWidth={1}>
          <NavBarButtonTabs />
        </Container>
      )}
    </>
  );
};
