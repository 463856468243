import styled from 'emotion-native-extended';
import {borders, color, space, layout, flexbox} from 'styled-system';
import {View} from 'react-native';
import React from 'react';
import {isWeb} from '../utils/utils';

export const Container = ({children, webHeight, height, ...props}) => {
  height = webHeight && isWeb() ? webHeight : height;
  return (
    <StyledView {...props} height={height}>
      {children}
    </StyledView>
  );
};

export const StyledView = styled(View)(color, space, layout, flexbox, borders);

StyledView.defaultProps = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'row',
};
