import merge from 'lodash/merge';
import toastTheme from './toastTheme';
import {isWeb, toastIsLarger} from '../utils/utils';

const webToastConfig = {
  toastStyles: {
    alignSelf: 'flex-end',
    minHeight: 'auto',
    mt: '32px',
    mb: '0',
  },
};

const webToastLargeConfig = {
  toastStyles: {
    width: '400px',
  },
};

export const createToastConfig = (
  iconColor = 'success',
  bgColor = 'background',
  buttonColor = 'button',
  icon = 'check-circle',
) => {
  //Not sure why the styledcomponents theme provider is not working for web :/
  let toastConfig = {
    toastStyles: {
      bg: toastTheme.colors[bgColor],
    },
    iconColor: toastTheme.colors[iconColor],
    // iconFamily: 'Entypo',
    iconName: icon,
    accentColor: toastTheme.colors[iconColor],

    // messageProps: {
    //   color: 'green',
    // },
    closeButtonStyles: {
      bg: toastTheme.colors[buttonColor],
    },
  };

  if (isWeb()) {
    toastConfig = merge(toastConfig, webToastConfig);
    if (toastIsLarger()) {
      toastConfig = merge(toastConfig, webToastLargeConfig);
    }
  }
  return toastConfig;
};

export const successToastConfig = () => {
  return createToastConfig('success', 'background', 'button', 'check-circle');
};

export const errorToastConfig = () => {
  return createToastConfig('error', 'background', 'button', 'x-circle');
};

export const infoToastConfig = () => {
  return createToastConfig('info', 'background', 'button', 'alert-circle');
};
