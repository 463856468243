import styled from '@emotion/native';
import {
  border,
  boxShadow,
  color,
  layout,
  space,
  typography,
} from 'styled-system';
import {Button as NativeButton} from 'react-native';

export const Button = styled(NativeButton)(
  color,
  space,
  typography,
  border,
  layout,
  boxShadow,
);

Button.defaultProps = {
  fontSize: 2,
  // fontFamily: 'Roboto',
};
