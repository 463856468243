import React, {useContext} from 'react';
import {Container} from '../components/Container';
import {UserAuthContext} from '../context/UserAuthContext';
import {Avatar} from 'react-native-elements';
import {Text} from '../components/Text';
import {Button} from '../components/Button';
import {useHistory} from 'react-router-dom';

export const Profile = () => {
  const userAuthContext = useContext(UserAuthContext);
  const history = useHistory();

  const signOut = () => {
    userAuthContext.signOut();
    history.push('/login');
  };

  return (
    <Container flexDirection={'column'} maxWidth={1024} alignSelf={'center'}>
      <Container flexDirection={{_: 'column', lg: 'row'}}>
        <Avatar
          size={200}
          // onPress={() => {
          //   console.log('edit your profile picture');
          // }}
          overlayContainerStyle={{
            backgroundColor: '#2684FF',
            borderRadius: 5,
          }}
          title="MT"
          activeOpacity={0.7}
        />
        <Container
          flexDirection={'column'}
          alignItems={'flex-start'}
          pl={{_: 0, lg: 4}}>
          <Text>First Name Last Name</Text>
          <Text>{userAuthContext.currentUser.email}</Text>
          <Text>Job Title</Text>
        </Container>
      </Container>

      <Text>Other options for Profile</Text>

      <Button onPress={signOut} title={'Sign Out'} />
    </Container>
  );
};
