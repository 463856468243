import {Redirect, Route} from 'react-router-dom';
import React, {useContext, useEffect, useState} from 'react';
import {UserAuthContext} from '../context/UserAuthContext';

export {BrowserRouter as Router, Route, Switch} from 'react-router-dom';

export const AuthRoute = ({component: C, props: cProps, ...rest}) => {
  const [isAuth, setIsAuth] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const userAuthContext = useContext(UserAuthContext);
  //Swap out for auth and user stored in a context
  useEffect(() => {
    async function CheckAuth() {
      await userAuthContext
        .checkAuth()
        .then(() => {
          setIsAuth(true);
          setIsLoading(false);
        })
        .catch((e) => {
          setIsAuth(false);
          setIsLoading(false);
        });
    }
    CheckAuth();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {!isLoading && (
        <Route
          {...rest}
          render={(props) =>
            isAuth ? (
              <C {...props} {...cProps} />
            ) : (
              <Redirect
                to={{
                  pathname: '/login',
                  search: `?redirect=${props.location.pathname}${props.location.search}`,
                  state: {from: props.location},
                }}
              />
            )
          }
        />
      )}
    </>
  );
};

//TODO: if we need an unauthRoute or if route will just work
// function querystring(name, url = window.location.href) {
//   name = name.replace(/[[]]/g, '\\$&');
//
//   const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)', 'i');
//   const results = regex.exec(url);
//
//   if (!results) {
//     return null;
//   }
//   if (!results[2]) {
//     return '';
//   }
//
//   return decodeURIComponent(results[2].replace(/\+/g, ' '));
// }
//
// export const UnauthRoute = ({
//   component: C,
//   props: cProps,
//   isAuthenticated: isAuth,
//   ...rest
// }) => {
//   const redirect = querystring('redirect');
//   return (
//     <Route
//       {...rest}
//       render={(props) =>
//         !isAuth ? (
//           <C {...props} {...cProps} />
//         ) : (
//           <Redirect
//             to={redirect === '' || redirect === null ? '/' : redirect}
//           />
//         )
//       }
//     />
//   );
// };
