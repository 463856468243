import {Container} from '../components/Container';
import {useForm} from 'react-hook-form';
import {ReactHookFormComponent} from '../components/react-hook-form-components/ReactHookFormComponent';
import {TextInput} from '../components/TextInput';
import {Button} from '../components/Button';
import {Auth} from '@aws-amplify/auth';
import React, {useState} from 'react';
import * as yup from 'yup';
import {yupResolver} from '@hookform/resolvers';
import {CheckEmailSplashScreen} from '../components/CheckEmailSplashScreen';
import {useHistory} from 'react-router-dom';
import {errorToastConfig} from '../theme/toastConfigs';
import {useToast} from 'react-native-styled-toast';

const ForgotPasswordRequestSchema = yup.object().shape({
  email: yup.string().required(),
});

export const ForgotPasswordRequest = () => {
  const [requestComplete, setRequestComplete] = useState(false);
  const {errors, control, handleSubmit} = useForm({
    mode: 'onBlur',
    resolver: yupResolver(ForgotPasswordRequestSchema),
  });
  const {toast} = useToast();

  const history = useHistory();

  const Submit = async (data) => {
    try {
      await Auth.forgotPassword(data.email.toLowerCase());
      setRequestComplete(true);
    } catch (e) {
      toast({
        message: 'Something Went Wrong',
        subMessage: e.message,
        duration: 0,
        ...errorToastConfig(),
      });
    }
  };

  return (
    <Container flexDirection={'column'}>
      {requestComplete ? (
        <CheckEmailSplashScreen />
      ) : (
        <>
          <ReactHookFormComponent
            errors={errors}
            control={control}
            name={'email'}
            component={<TextInput />}
            label={'email'}
          />
          <Button onPress={handleSubmit(Submit)} title={'Search'} />
          <Button
            onPress={() => history.push('/login')}
            title={'Go Back To Login'}
          />
        </>
      )}
    </Container>
  );
};
