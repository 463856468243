import React from 'react';

import {AuthRoute, Route, Switch} from './router/router';
import {FirstPage, SecondPage} from './Examples';
import {Signup} from './scene/Signup';
import {Login} from './scene/Login';
import {SignupConfirmation} from './scene/SignupConfirmation';
import {ForgotPasswordRequest} from './scene/ForgotPasswordRequest';
import {ResendConfirmation} from './scene/ResendConfirmation';
import {ForgotPasswordConfirmation} from './scene/ResetPasswordConfirmation';
import {Profile} from './scene/Profile';

export const Routes = () => {
  let standardRouteProps = {
    exact: true,
  };
  return (
    <Switch>
      <Route path={'/signup'} component={Signup} {...standardRouteProps} />
      <Route path={'/login'} component={Login} {...standardRouteProps} />
      <Route
        path={'/forgotPassword'}
        component={ForgotPasswordRequest}
        {...standardRouteProps}
      />
      <Route
        path={'/forgotPassword/confirm'}
        component={ForgotPasswordConfirmation}
        {...standardRouteProps}
      />
      <Route
        path={'/signup/confirm'}
        component={SignupConfirmation}
        {...standardRouteProps}
      />
      <Route
        path={'/signup/confirm/resend'}
        component={ResendConfirmation}
        {...standardRouteProps}
      />
      <AuthRoute path={'/'} component={FirstPage} {...standardRouteProps} />
      <AuthRoute
        path={'/preferences'}
        component={SecondPage}
        {...standardRouteProps}
      />
      <AuthRoute
        path={'/schedule'}
        component={FirstPage}
        {...standardRouteProps}
      />
      <AuthRoute
        path={'/coverShift'}
        component={SecondPage}
        {...standardRouteProps}
      />
      <AuthRoute
        path={'/admin'}
        component={FirstPage}
        {...standardRouteProps}
      />
      <AuthRoute
        path={'/profile'}
        component={Profile}
        {...standardRouteProps}
      />
    </Switch>
  );
};
