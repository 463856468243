import styled from '@emotion/native';
import {color, space, typography, variant, flexbox} from 'styled-system';
import {Text as NativeText} from 'react-native';

export const Text = styled(NativeText)(
  variant({
    prop: 'variant',
    variants: {
      primary: {
        color: 'text',
      },
      link: {
        color: 'blue',
      },
      error: {
        color: 'error',
      },
    },
  }),
  color,
  space,
  typography,
  flexbox,
  (props) =>
    props.active &&
    variant({
      prop: 'stateVariant',
      variants: {navBarButton: {color: 'blue1'}},
    }),
  (props) =>
    props.focused &&
    variant({
      prop: 'stateVariant',
      variants: {navBarButton: {color: 'blue1'}},
    }),
  //focus flashes before pressed.
  (props) =>
    props.pressed &&
    variant({
      prop: 'stateVariant',
      variants: {navBarButton: {color: 'blue1'}},
    }),
  //Hover not working even on react-native-web@canary
  (props) =>
    props.hovered &&
    variant({
      prop: 'stateVariant',
      variants: {navBarButton: {color: 'blue1'}},
    }),
);

Text.defaultProps = {
  fontSize: 2,
  variant: 'primary',
  // fontFamily: 'Roboto',
};
