import {Container} from '../components/Container';
import {useForm} from 'react-hook-form';
import {ReactHookFormComponent} from '../components/react-hook-form-components/ReactHookFormComponent';
import {TextInput} from '../components/TextInput';
import {Button} from '../components/Button';
import React, {useContext, useEffect} from 'react';
import * as yup from 'yup';
import {yupResolver} from '@hookform/resolvers';
import {useHistory, useLocation} from 'react-router-dom';
import {Link} from '../components/Link';
import queryString from 'query-string';
import {UserAuthContext} from '../context/UserAuthContext';
import {errorToastConfig, infoToastConfig} from '../theme/toastConfigs';
import {useToast} from 'react-native-styled-toast';

const LoginSchema = yup.object().shape({
  email: yup.string().required(),
  password: yup.string().required(),
});

export const Login = () => {
  const history = useHistory();
  const location = useLocation();
  const userAuthContext = useContext(UserAuthContext);
  const {toast} = useToast();

  let {from} = location.state || {from: {pathname: '/'}};

  const queryParams = queryString.parse(history.location.search);
  const email = queryParams.email;
  const message = queryParams.message;

  const {errors, control, handleSubmit} = useForm({
    mode: 'onBlur',
    defaultValues: {
      email: email,
    },
    resolver: yupResolver(LoginSchema),
  });

  const Submit = async (data) => {
    try {
      let user = await userAuthContext.signIn(
        data.email.toLowerCase(),
        data.password,
      );
      if (user) {
        history.replace(from);
      }
    } catch (e) {
      toast({
        message: 'Login Failed',
        subMessage: e.message,
        duration: 0,
        ...errorToastConfig(),
      });
    }
  };

  useEffect(() => {
    if (message) {
      toast({
        message: message,
        duration: 300000,
        ...infoToastConfig(),
      });
    }
  }, [message, toast]);

  return (
    <Container flexDirection={'column'}>
      <ReactHookFormComponent
        errors={errors}
        control={control}
        name={'email'}
        component={<TextInput />}
        label={'email'}
        pb={2}
      />
      <ReactHookFormComponent
        errors={errors}
        control={control}
        name={'password'}
        component={<TextInput />}
        label={'password'}
        pb={2}
      />
      <Button onPress={handleSubmit(Submit)} title={'Log In'} />
      <Link onPress={() => history.push('/forgotPassword')}>
        Forgot Your Password?
      </Link>
      <Button onPress={() => history.push('/signup')} title={'Signup'} />
    </Container>
  );
};
