import React from 'react';
import {NavBarButton} from './NavBarButton';
import {useMediaQuery} from 'react-responsive';
import {isWeb, tabletOrSmallerMobile} from '../../utils/utils';
import {useRouteMatch, useHistory} from 'react-router-dom';

export const NavBarButtonTabs = () => {
  let requiredProps = {};
  let tabletOrSmaller;

  let history = useHistory();

  //Can't get useMediaQuery to work on mobile:(
  tabletOrSmaller = useMediaQuery({
    maxWidth: 767,
  });
  if (!isWeb()) {
    tabletOrSmaller = tabletOrSmallerMobile();
  }

  if (tabletOrSmaller) {
    requiredProps = {pressableStyles: {spacing: 'equal'}, width: '100%'};
  }

  return (
    <>
      <NavBarButton
        onPress={() => history.push('/')}
        iconName="home"
        label={'Home'}
        active={useRouteMatch({path: '/', exact: true})}
        {...requiredProps}
      />
      <NavBarButton
        onPress={() => history.push('/preferences')}
        iconName="sliders"
        label={'Preferences'}
        active={useRouteMatch('/preferences')}
        {...requiredProps}
      />
      <NavBarButton
        onPress={() => history.push('/schedule')}
        iconName="calendar"
        label={'Schedule'}
        active={useRouteMatch('/schedule')}
        {...requiredProps}
      />
      <NavBarButton
        onPress={() => history.push('/coverShift')}
        iconName="shuffle"
        label={'Cover Shift'}
        active={useRouteMatch('/coverShift')}
        {...requiredProps}
      />
      <NavBarButton
        onPress={() => history.push('/admin')}
        iconName="layers"
        label={'Admin'}
        active={useRouteMatch('/admin')}
        {...requiredProps}
      />
    </>
  );
};
