import {Auth} from '@aws-amplify/auth';

export const apiAuthHeader = async () => {
  let cognitoUser = await Auth.currentAuthenticatedUser();
  let currentSession = cognitoUser.signInUserSession;
  let authToken = currentSession.idToken.jwtToken;
  let opts = {
    headers: {
      // Override the Authorization header with JWT token as required by API Gateway Cognito Authorizers
      // Authorization: cognitoUser.idToken.jwtToken
      Authorization: authToken,
    },
  };
  return opts;
};
