import {Container} from '../Container';
import React, {useContext} from 'react';
import {Avatar} from 'react-native-elements';
import {NavLogo} from './NavLogo';
import {NavBarButton} from './NavBarButton';
import {NavBarButtonTabs} from './NavBarButtonTabs';
import {useMediaQuery} from 'react-responsive';
import {isWeb, tabletOrSmallerMobile} from '../../utils/utils';
import {UserAuthContext} from '../../context/UserAuthContext';
import {useHistory} from 'react-router-dom';

export const WebNavBar = () => {
  const userAuthContext = useContext(UserAuthContext);
  const history = useHistory();
  let tabletOrSmaller;
  //Can't get this to work on mobile:(
  tabletOrSmaller = useMediaQuery({
    maxWidth: 767,
  });
  if (!isWeb()) {
    tabletOrSmaller = tabletOrSmallerMobile();
  }

  return (
    <Container alignSelf={'center'} maxWidth={1024} width={'100%'}>
      <Container flexGrow={1} justifyContent={'flex-start'}>
        <NavLogo onPress={() => console.log('hello')} width={128} py={2} />
        {userAuthContext.auth && (
          <>{!tabletOrSmaller && <NavBarButtonTabs />}</>
        )}
      </Container>

      {userAuthContext.auth && (
        <Container flexGrow={1} justifyContent={'flex-end'}>
          <NavBarButton
            onPress={() => console.log('hello')}
            iconName="bell"
            width={48}
          />
          <NavBarButton
            onPress={() => console.log('hello')}
            iconName="settings"
            width={48}
          />
          <Container mr={3} ml={2} my={'auto'}>
            <Avatar
              size="small"
              onPress={() => {
                history.push('/profile');
              }}
              overlayContainerStyle={{
                backgroundColor: '#2684FF',
                borderRadius: 5,
              }}
              title="MT"
              activeOpacity={0.7}
            />
          </Container>
        </Container>
      )}
    </Container>
  );
};
