import React from 'react';
import {Container} from './components/Container';
import {Text} from './components/Text';

export const FirstPage = () => {
  return (
    <Container>
      <Text>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean ut
        vestibulum ligula. Nullam ante urna, placerat non ullamcorper vitae,
        posuere a justo. Donec non tristique odio. Curabitur vitae ligula
        fringilla, pulvinar magna id, interdum nunc. Pellentesque habitant morbi
        tristique senectus et netus et malesuada fames ac turpis egestas.
        Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere
        cubilia curae; Nunc hendrerit, libero vel aliquet congue, neque est
        egestas ante, at tincidunt est quam vel augue. Cras libero risus, rutrum
        sed diam sed, fermentum dapibus ipsum. Morbi lacus nunc, luctus a
        facilisis quis, placerat vel neque. Nunc sit amet finibus massa, ac
        pellentesque mauris. Duis accumsan libero sit amet laoreet hendrerit.
        Suspendisse euismod nisl massa, condimentum sollicitudin neque ultrices
        ut. Sed dictum vitae enim in tincidunt. Nulla vehicula urna nec ipsum
        fermentum eleifend. Maecenas ante felis, accumsan ac risus eget,
        facilisis accumsan erat. Nam et orci vel augue lacinia commodo. Nulla
        lobortis pharetra sem. Fusce sed libero ut erat auctor hendrerit sit
        amet vel elit. Phasellus cursus nulla eget eleifend fermentum. Maecenas
        lobortis massa magna, at suscipit risus porta at. Pellentesque interdum
        leo commodo semper suscipit. Curabitur eget neque nec lacus lacinia
        vulputate. Duis semper dui mi, et malesuada ex luctus a. Mauris vel sem
        nibh. Pellentesque pellentesque tortor a augue pharetra, vel congue
        nulla porta. Nam bibendum hendrerit lorem. Aliquam nec nisl vitae ligula
        rhoncus pulvinar eget vitae diam. Phasellus sed dignissim mauris. In
        tempor dignissim vulputate. Fusce condimentum, justo eu viverra
        sollicitudin, nunc ex fermentum magna, vitae vehicula metus orci ac sem.
        Nullam ornare ipsum id est euismod ornare. Proin iaculis, augue eu
        congue vestibulum, odio libero hendrerit libero, in mattis massa nulla
        eget nisi. Morbi efficitur metus id justo congue congue. Nam et lacus ac
        turpis euismod ultricies. Nam venenatis gravida quam ut venenatis.
        Mauris et fermentum enim. Nam rutrum lorem non dolor mollis laoreet.
        Morbi placerat, urna vitae congue aliquet, diam erat facilisis turpis,
        in consectetur sapien risus in ipsum. Nulla mattis risus facilisis sem
        porttitor, dictum accumsan justo egestas. Praesent quis arcu mauris.
        Orci varius natoque penatibus et magnis dis parturient montes, nascetur
        ridiculus mus. Ut lacinia sagittis tortor. Proin felis justo, tempor et
        massa eu, feugiat interdum ligula. Donec tempor volutpat nibh, eu
        pellentesque felis sodales eget. Cras at pellentesque lorem. Praesent id
        ipsum euismod, auctor lorem sed, auctor nulla. Donec at justo non urna
        congue feugiat non ac eros. Nunc fringilla ligula in magna venenatis
        pulvinar. Quisque ut ipsum dolor.
      </Text>
    </Container>
  );
};

export const SecondPage = () => {
  return (
    <Container>
      <Text>second page</Text>
    </Container>
  );
};
