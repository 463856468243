import {Pressable} from '../StyledPressable';
import {Text} from '../Text';
import React from 'react';

export const NavLogo = ({onPress, ...props}) => {
  return (
    <Pressable onPress={onPress} {...props}>
      <Text
        color={'blue1'}
        textAlign={'center'}
        fontWeight={'700'}
        fontSize={4}>
        ShiftFox
      </Text>
    </Pressable>
  );
};
