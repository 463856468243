import React from 'react';
import {Controller} from 'react-hook-form';
import {Container} from '../Container';
import {Text} from '../Text';
import {ReactHookFormError} from './ReaHookFormError';

export const ReactHookFormComponent = ({
  name,
  component,
  label,
  errors,
  control,
  controllerOnChange,
  controllerDefaultValue,
  labelStyles,
  ...rest
}) => {
  return (
    <Container flexDirection={'column'} {...rest}>
      {label && <Text {...labelStyles}> {label} </Text>}

      <Controller as={component} control={control} name={name} />

      {errors[name] && <ReactHookFormError message={errors[name].message} />}
    </Container>
  );
};
