import {Container} from '../components/Container';
import {useForm} from 'react-hook-form';
import {ReactHookFormComponent} from '../components/react-hook-form-components/ReactHookFormComponent';
import {TextInput} from '../components/TextInput';
import {Button} from '../components/Button';
import {Auth} from '@aws-amplify/auth';
import React from 'react';
import {YupErrorMessage} from '../components/react-hook-form-components/YupFormErrorMessage';
import * as yup from 'yup';
import {yupResolver} from '@hookform/resolvers';
import {useHistory} from 'react-router-dom';
import queryString from 'query-string';
import {useToast} from 'react-native-styled-toast';
import {errorToastConfig} from '../theme/toastConfigs';

const ResetPasswordVerificationSchema = yup.object().shape({
  password: yup
    .string()
    .required(<YupErrorMessage label={'Password Is Required'} />),
  confirmPassword: yup
    .string()
    .oneOf(
      [yup.ref('password'), null],
      <YupErrorMessage label={'Passwords Do Not Match'} />,
    ),
});

export const ForgotPasswordConfirmation = () => {
  const history = useHistory();

  const queryParams = queryString.parse(history.location.search);
  const locationEmail = queryParams.email;
  const confirmationCode = queryParams.code;

  const {errors, control, handleSubmit} = useForm({
    mode: 'onBlur',
    resolver: yupResolver(ResetPasswordVerificationSchema),
    defaultValues: {
      email: locationEmail,
      confirmationCode: confirmationCode,
    },
  });
  const {toast} = useToast();

  const Submit = async (data) => {
    try {
      await Auth.forgotPasswordSubmit(
        data.email.toLowerCase(),
        data.confirmationCode,
        data.password,
      );
      history.push(
        `/login?email=${data.email.toLowerCase()}&message=Your password has been reset, for security reasons we need you to signin again`,
      );
    } catch (e) {
      toast({
        message: 'Something Went Wrong',
        subMessage: e.message,
        duration: 0,
        ...errorToastConfig(),
      });
    }
  };

  return (
    <Container flexDirection={'column'}>
      <ReactHookFormComponent
        errors={errors}
        control={control}
        name={'email'}
        component={<TextInput />}
        label={'Email'}
        display={locationEmail ? 'none' : undefined}
      />
      <ReactHookFormComponent
        errors={errors}
        control={control}
        name={'confirmationCode'}
        component={<TextInput />}
        label={'Confirmation Code'}
        display={confirmationCode ? 'none' : undefined}
      />
      <ReactHookFormComponent
        errors={errors}
        control={control}
        name={'password'}
        component={<TextInput />}
        label={'password'}
      />
      <ReactHookFormComponent
        errors={errors}
        control={control}
        name={'confirmPassword'}
        component={<TextInput />}
        label={'confirmPassword'}
      />
      <Button onPress={handleSubmit(Submit)} title={'Reset Password'} />
    </Container>
  );
};
